window.USeek ||= {}

class USeek.Util
  @aspectRatio: ()->
    if USeek.player().vertical() then 9/16 else 16/9

  @clickPosition: (event, element)->
    offset = USeek.$(element).offset()
    x = event.clientX + USeek.$(window).scrollLeft() - Math.floor(offset.left)
    y = event.clientY + USeek.$(window).scrollTop() - Math.floor(offset.top) + 1
    [x,y]

  @normalizedPositions: (positions, container, aspectRatio = @aspectRatio())->
    @normalizedPosition(coords, container, aspectRatio) for coords in positions

  @normalizedPosition: (pos, container, aspectRatio = @aspectRatio(), snap = false)->
    containerWidth = container.width()
    containerHeight = container.height()
    containerAspectRatio = containerWidth / containerHeight
    if aspectRatio >= containerAspectRatio
      actualWidth = containerWidth
      actualHeight = containerWidth / aspectRatio
      y = (pos[1] - ((containerHeight - actualHeight) / 2)) / actualHeight

      if snap and y < 0 then y = 0
      if snap and y > 1 then y = 1
      if y >= 0 and y <= 1 then [@round(pos[0] / actualWidth, 3), @round(y, 3)] else null
    else
      actualHeight = containerHeight
      actualWidth = containerHeight * aspectRatio
      x = (pos[0] - ((containerWidth - actualWidth) / 2)) / actualWidth

      if snap and x < 0 then x = 0
      if snap and x > 1 then x = 1

      if x >= 0 and x <= 1 then [@round(x, 3), @round(pos[1] / actualHeight, 3)] else null

  @denormalizedPositions: (positions, container, aspectRatio = @aspectRatio())->
    @denormalizedPosition(coords, container, aspectRatio) for coords in positions

  @denormalizedPosition: (pos, container, aspectRatio = @aspectRatio())->
    height = Math.min(container.width() / aspectRatio, container.height())
    width = Math.min(container.height() * aspectRatio, container.width())

    [(pos[0] * width) + (container.width() - width) / 2, (pos[1] * height) + (container.height() - height) / 2]

  @random = (array)->
    array[Math.floor(Math.random()*array.length)]

  @round = (number, decimal_places)->
    Math.round(number*Math.pow(10,decimal_places)) / Math.pow(10,decimal_places)

  @urlParams = (url = window.location.href)->
    params = {}
    query = url.split('?')[1]
    components = query.split('&') if query?

    if components?
      for section in components
        parts = section.split('=')
        params[parts[0]] = decodeURIComponent(parts[1])

    params

  @validEmail = (email)->
    email.length > 0 && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)

  @validPhoneNumber = (phone_number)->
    phone_number.length > 0

  @openPopup = (url, width = 640, height = 480)->
    options = "location=no,scrollbars=yes,status=no,width=#{width},height=#{height}"
    if !USeek.getClass?('Mobile').isMobile() && window.screen?
      options += ",top=#{(window.screen.height-height)/2},left=#{(window.screen.width-width)/2}"

    window.open(url, '_blank', options)