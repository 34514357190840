require('javascripts/useek/noconflict/mobile-detect')
global.MobileDetect = require('mobile-detect')

class USeek.Mobile
  @_mobileDetect = USeek.Conflict.resolve('MobileDetect')
  @_detect = new @_mobileDetect(window.navigator.userAgent)

  # Device orientation

  @orientation: ()->
    if document.documentElement.clientWidth > document.documentElement.clientHeight then 'landscape' else 'portrait'

  @landscape: ()->
    @orientation() == 'landscape'

  @portrait: ()->
    @orientation() == 'portrait'

  # Device identification

  @isMobile: ()->
    @_detect.mobile()? || window.TJ_API?

  @phone: ()->
    @_detect.phone()?

  @tablet: ()->
    @_detect.tablet()?

  @android: ()->
    @_detect.is('AndroidOS')

  @iphone: ()->
    @_detect.is('iPhone')

  @ipad: ()->
    @_detect.is('iPad')

  @ios: ()->
    @iphone() || @ipad()

  @isIOS9: ()->
    version = @_detect.version('iOS')
    version >= 9 && version < 10

  @isIOS10: ()->
    version = @_detect.version('iOS')
    version >= 10 && version < 11

  @isIOS11: ()->
    version = @_detect.version('iOS')
    version >= 11 && version < 12

  @isSupportedIOS: (minVersion = 9)->
    @_detect.version('iOS') >= minVersion

  @facebook: ()->
    @_detect.match('FBAV') || @_detect.match('FBAN')

  @twitter: ()->
    @_detect.match('Twitter')

  @instagram: ()->
    @_detect.match('Instagram')