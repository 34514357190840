window.USeek ||= {}

# setup variables

USeek._classes ||= {}
USeek._instances ||= {}
USeek._vendors ||= {}

USeek.Player ||= {}
for item in ['Backend', 'Components', 'Extensions', 'Tech']
  USeek.Player[item] ||= {}

# define utility methods

USeek.namespace = (name, block)->
  target = parent = USeek
  for item in name.split '.'
    parent = target
    target = target[item] ||= {}
  block target, parent

USeek.getClass = (name)->
  USeek._classes[name]

USeek.getInstance = (name)->
  USeek._instances[name]

USeek.getVendor = (name)->
  USeek._vendors[name]

USeek.hideClass = (name)->
  USeek._classes[name] = USeek[name]
  delete USeek[name]

USeek.setInstance = (name, instance, shortcut = false)->
  USeek._instances[name] ||= instance
  if shortcut
    USeek[name] = ( ()=> USeek.getInstance(name) )

    # it seems that jQuery handle custom events internally and may not work correctly so it's better to use pure JS
    player = document.getElementById('useek-player')
    type = "#{name}-ready"
    if player? && player.dispatchEvent?
      if typeof Event == 'function'
        event = new Event(type)
      else
        event = document.createEvent('Event')
        event.initEvent(type, true, true)
      player.dispatchEvent(event)

USeek.setVendor = (name, vendor)->
  USeek._vendors[name] = vendor

USeek.$ = (selector = null)->
  jquery = USeek.getVendor('jQuery')
  if selector? then jquery(selector) else jquery

# handle external libraries

if typeof jQuery isnt 'undefined'
  USeek.setVendor('jQuery', jQuery.noConflict(true))

if typeof Cookies isnt 'undefined' && typeof USeek.Conflict isnt 'undefined'
  USeek.Conflict.resolve('Cookies')

if typeof I18n isnt 'undefined' && typeof USeek.Conflict isnt 'undefined'
  USeek.Conflict.resolve('I18n')

if typeof Fingerprint2 isnt 'undefined' && typeof USeek.Conflict isnt 'undefined'
  USeek.Conflict.resolve('Fingerprint2')

if typeof PerfectScrollbar isnt 'undefined' && typeof USeek.Conflict isnt 'undefined'
  USeek.Conflict.resolve('PerfectScrollbar')