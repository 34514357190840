$.fn.isInViewport = ()->
  tolerance = $(window).height()*0.3
  elementTop = $(this).offset().top + tolerance
  elementBottom = elementTop + $(this).outerHeight() - tolerance
  viewportTop = $(window).scrollTop()
  viewportBottom = viewportTop + $(window).height()
  elementBottom > viewportTop && elementTop < viewportBottom;

$ ()->
#  resizeHeader()
  setupContactForm()
  setupIframeTakeover()
  showStats()
  slideLine($('#header .content hr'), 2000)

#  $(window).on 'resize', ()->
#    resizeHeader()

  $(window).on 'resize scroll', ()->
    showStats()

  $('.scroll-arrow').on 'click', ()->
    $('body,html').animate({ scrollTop: $('#content').offset().top }, 800)

#window.resizeHeader = ()->
#  $('#header-new .row').css(height: window.innerHeight)

window.setupContactForm = ()->
  $('#contact-form').on 'submit', ()->
    message = $('.form-message')
    message.removeClass('error').removeClass('success')
    valid = true

    unless USeek.Util.validEmail($('#contact_request_email').val())
      message.html('Email is invalid.').addClass('error')
      valid = false

    $(this).find('input.string').each ()->
      if $(this).val().length == 0
        message.html('All fields are required.').addClass('error')
        valid = false
        return

    message.html("Message sent! We'll contact you shortly.").addClass('success') if valid
    valid

window.setupIframeTakeover = ()->
  return unless USeek.Mobile.isMobile()
  $(window).on 'message', (event)->
    event = event.originalEvent if event.originalEvent
    if event.data == 'player-activated'
      iframe = $(event.source.frameElement)
      @$('body').addClass('useek-fullscreen')
      @$('body *').addClass('hidden-by-useek')
      iframe.removeClass('hidden-by-useek').addClass('useek-fullscreen-player')
      iframe.parents().removeClass('hidden-by-useek').addClass('useek-player-parent')
      iframe.find('*').removeClass('hidden-by-useek')

window.showStats = (delay = 1000)->
  stats = $('.stats:not(.completed)')
  return unless stats.length > 0 && stats.isInViewport()
  handleCountUp()

window.slideLine = (line, time)->
  return unless line.length > 0
  width = line.width()
  line.width(0)
  line.css(visibility: 'visible')
  line.animate(width: width, time)