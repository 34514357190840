require('javascripts/useek/setup')

class USeek.Base
  $: (selector = null)->
    USeek.$(selector)

  cookies: ()->
    @getVendor('Cookies')

  i18n: (label, params)->
    @getVendor('I18n').t(label, params)

  mobile: ()->
    @getClass('Mobile')

  util: ()->
    @getClass('Util')

  getClass: (name)->
    USeek.getClass(name)

  getInstance: (name)->
    USeek.getInstance(name)

  getVendor: (name)->
    USeek.getVendor(name)

  setInstance: (name, shortcut = false)->
    USeek.setInstance(name, this, shortcut)