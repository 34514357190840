jQuery.fn.extend
  showInline: ()->
    this.css('display', 'inline-block')

window.displayFlash = (type, message, time = 5000)->
  cssType = switch type
    when 'error'  then 'danger'
    when 'notice' then 'info'
    else type

  $("html, body").animate({ scrollTop: 0 }, "slow");
  flash = $('<div>', { class: "alert alert-dismissable alert-#{cssType}", style: 'display: none' })
  $('.page-messages').append(flash)
  flash.append('<button class="close" data-dismiss="alert"></button>')
  flash.append("<strong>#{type}!</strong> ")
  flash.append(message)
  flash.fadeIn()
  setTimeout(( ()-> flash.fadeOut() ), time)

  return

window.executeWithTimeout = (callback, timeout)->
  if timeout > 0
    setTimeout ( ()-> callback()? ), timeout
  else
    callback?()

  return