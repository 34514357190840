window.USeek ||= {}

class USeek.Conflict
  @_preserved = {}

  @prepare: (name)->
    @_preserved[name] = window[name]
    window[name] = null

  @resolve: (name)->
    USeek.setVendor(name, window[name])
    window[name] = @_preserved[name]
    USeek.getVendor(name)